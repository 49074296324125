
import { useState } from 'react';

const faqData = [
  {
    question: "Frequently asked questions",
    answer: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores aperiam reprehenderit sequi quisquam iusto eligendi ratione non repellendus cupiditate voluptatum. Voluptas dolorem at atque dolorum vitae iure inventore repellat et?",
  },
  {
    question: "Another question?",
    answer: "This is the answer to the second question. Lorem ipsum dolor sit amet consectetur adipisicing elit.",
  },
];

const  Faq=()=> {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleHandler = (index:any) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="flex flex-col justify-center items-center py-16 md:px-16 px-5 gap-10 font-gtbuddy">
      <div>
        <h1 className="md:text-4xl text-2xl font-bold py-2">Frequently Asked Questions</h1>
      </div>
      <div className="w-full md:w-4/5 grid grid-cols-1 gap-6">
        {faqData.map((item, index) => (
          <div key={index} className="w-full">
            <div className="flex justify-between items-center px-3">
              <div className="text-xl">{item.question}</div>
              <div
                className="relative hover:cursor-pointer p-2"
                onClick={() => toggleHandler(index)}
              >
                <div className={`absolute h-0.5 w-5 bg-black right-1 ${activeIndex === index ? '' : 'rotate-90'}`}></div>
                <div className="absolute h-0.5 w-5 bg-black right-1"></div>
              </div>
            </div>
            {activeIndex === index && (
              <div className=" px-3 py-5">
                {item.answer}
              </div>
            )}
          
          </div>
        ))}
      </div>
    </div>
  );
}

export default Faq;

import {
    CognitoUserPool
} from 'amazon-cognito-identity-js';
import type { User } from '../../app/services/auth'
import { Business } from './api/business';
import { getCurrentBusiness } from './user';
export type AuthState = {
    user? : User | null, 
    token?: string|null,
    idToken?: string|null,
    dialCode: string,
    locale: string,
    currentBusiness?: Business | null
}
  
export const poolData = {
    UserPoolId: process.env.REACT_APP_POOL_ID ?? '', // Your user pool id here
    ClientId: process.env.REACT_APP_CLIENT_ID ?? '', // Your client id here
};
export const userPool = new CognitoUserPool(poolData);

export const initialState:AuthState = {
    user: null,
    token: null,
    idToken: null,
    dialCode: '+91',
    locale: 'en',
}
// get current cognito user from session
export const getCurrentUser = () => {
    let cognito = userPool.getCurrentUser();
    if(cognito){
        let token:any, idToken:any;
        // get token from session
        
        cognito.getSession(function (err:any, result:any) {
            if (result) {
                token = result.accessToken.getJwtToken();
                idToken = result.idToken.getJwtToken();
                
            }
        });

        cognito.getUserData(function (err, userData) {
            if (err) {
                console.log('cognito getUserData error:  '+err.message || JSON.stringify(err));

                return;
            }
            else {
                //type indexedObject = {[key:string]:string};
                let user:User = {};
                
                if(userData && userData.UserAttributes){
                    
                    userData.UserAttributes.forEach(element => {
                        user[element.Name] = element.Value;
                    });
                    initialState.user = user;
                    initialState.token = token;
                    initialState.idToken = idToken;
                    initialState.currentBusiness = getCurrentBusiness();
                    //navigate('/');
                }
                
                
                
            }
            
            
        });
    }
    return initialState;
};


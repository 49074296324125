import './App.css';
import LoginSignupForm from './features/forms/LoginSignupForm';
import BusinessList from './features/admin/business/businessList';
import {
  createBrowserRouter,
  Navigate,
  RouterProvider
} from "react-router-dom";
import Business from './features/admin/business/business';
import { PrivateOutlet } from './utils/PrivateOutlet';
import { BusinessOutlet } from './utils/BusinessOutlet';
import { UserContext } from './utils/UserContext';
import { useState } from 'react';
import Themes from './features/admin/themes/themes';
import Theme from './features/admin/themes/theme';
import ComingSoon from './features/admin/themes/Coming';
import ErrorBoundary from './features/errors/ErrorBoundary';
import Home from './features/home/Home';
import NewHome from './features/home/NewHome';
import { Employee } from './features/admin/entities/Employee';
import { getUserData, setUserData } from './app/services/user';
import { Student } from './features/admin/entities/Student';
import { Class } from './features/admin/entities/Class';
import { BaseTheme, BusinessWiseThemes } from './features/admin/BaseTheme';
import Plans from './features/admin/payment/plans';
import { Activity } from './features/admin/Activity';
import BusinessSettings from './features/admin/business/businiessSettings';
import BusinessListing from './features/admin/business/BusinessListing';
import BusinessDetails from './features/admin/business/BusinessDetails';
import { CustomEntity } from './features/admin/entities/BusinessEntity';

// Create a router and provide it to the RouterProvider
const router = createBrowserRouter([
  {
    path: "/",
    element: <PrivateOutlet></PrivateOutlet>,
    errorElement: <ErrorBoundary />,
    children: [
      {
        element: <Navigate to="/panel" />,
        path: "/"
      },
      {
        path: "/business",
        element: <Business />,
      },
      {
        path: "panel/manage/theme/business/:businessCode/:gridAction?/:id?",
        element: <BaseTheme />,
      },
      {
        path: "panel/manage/activity",
        element: <Activity />,
      },
      {
        path: "panel/manage/theme/:gridAction?/:id?",
        element: <BusinessWiseThemes />,
      },
      {
        path: "/panel",
        element: <BusinessOutlet />,
        errorElement: <ErrorBoundary />,
        children: [
          {
            index: true,
            element: <Themes />,
          },
          {
            path: "*",
            element: <NewHome />,
          },
          {
            path: "manage",
            element: <Themes />,
          },
          {
            path: "settings",
            element: <BusinessSettings />
          },
          {
            path: "manage/business",
            element: <BusinessListing />,
          },
          {
            path: "manage/business/:id?",
            element: <BusinessDetails />,
          },
          {
            path: "manage/employee/:gridAction?/:id?",
            element: <ComingSoon />,
          },
          {
            path: "manage/student/:gridAction?/:id?",
            element: <ComingSoon />,
          },
          {
            path: "manage/class/:gridAction?/:id?",
            element: <ComingSoon />,
          },
          {
            path: "business-entity/:entity/:gridAction?/:id?",
            element: <CustomEntity />,
          },
          {
            path: "listBusiness",
            element: <BusinessList />,
          },
          {
            path: "themes",
            element: <Themes />,
          },
          {
            path: "comingsoon",
            element: <ComingSoon />
          },
          {
            path: "plan",
            element: <Plans />
          },

        ],
      },
      {
        path: "theme/:type/:code/:pageCode/:id?",
        element: <Theme />,
      }
    ]
  },

  {
    path: "/login",
    element: <LoginSignupForm />,
  },
  {
    path: "/register",
    element: <LoginSignupForm type='signup' />,
  }

]);
function App() {
  var userData = {}!;
  userData = getUserData();
  const [value, setValue] = useState(userData);
  function handleValueChange(user: any) {
    setUserData(user, setValue);
  };
  var userDefaultValue = {
    user: value,
    setDiUser: handleValueChange
  }
  return (
    <>
      <UserContext.Provider value={userDefaultValue}>
        <RouterProvider router={router} />
      </UserContext.Provider>
    </>

  );
}
export default App;

import { api } from '../api'

export interface Business {
    id: string,
    code: string,
    name: string,
    type: string,
    amplifyDefaultDomain?:string,
    address?: string,
    entity?: string,
    amplifyAppId?: string,
    accountId?: string,
    customDomain?: string[],
    verifiedCustomDomain?: string[],
    account?: string,
    pk?: string,
    sk?: string,
    username?: string,
    restApiUrl?: string,
    s3Bucket?: string
}

type BusinessesResponse = Business[];

type BusinessAllResponse = {
  items: Business[],
  appDetails?: any,
  domainDetails?: any,
  jobDetails?: any
}
export const businessApi = api.injectEndpoints({
    endpoints: (build) => ({
      getBusinesses: build.query<BusinessesResponse, void>({
        query: () => ({ url: 'business' }),
        providesTags: (result = []) => [
          ...result.map(({ id }) => ({ type: 'Businesses', id }) as const),
          { type: 'Businesses' as const, id: 'LIST' },
        ],
      }),
      getAllBusinesses: build.query<BusinessAllResponse, void>({
        query: () => ({ url: 'business/all' }),
        providesTags: (result = {items: []}) => [
          ...result?.items.map(({ id }) => ({ type: 'Businesses', id }) as const),
          { type: 'Businesses' as const, id: 'LIST' },
        ]
      }),
      getBusinessDetails: build.query<BusinessAllResponse, string>({
        query: (id) => ({ url: `business/all?${id}`}),
        providesTags: (result = {items: []}) => [
          ...result?.items.map(({ id }) => ({ type: 'Businesses', id }) as const),
          { type: 'Businesses' as const, id: 'LIST'},
        ]
      }),
      addBusiness: build.mutation<Business, Partial<Business>>({
        query: (body) => ({
          url: `business`,
          method: 'POST',
          body,
        }),
        invalidatesTags: [{ type: 'Businesses', id: 'LIST' }],
      }),
      getBusiness: build.query<Business, string>({
        query: (id) => `business/${id}`,
        providesTags: (_post, _err, id) => [{ type: 'Businesses', id }],
      }),
      updateBusiness: build.mutation<Business, Partial<Business>>({
        query(data) {
          return {
            url: `business`,
            method: 'PATCH',
            body: data
          }
        },
        invalidatesTags: (post) => [{ type: 'Businesses', id: post?.id }],
      }),
      deleteBusiness: build.mutation<{ success: boolean; id: number }, number>({
        query(id) {
          return {
            url: `business/${id}`,
            method: 'DELETE',
          }
        },
        invalidatesTags: (post) => [{ type: 'Businesses', id: post?.id }],
      }),
      getErrorProne: build.query<{ success: boolean }, void>({
        query: () => 'error-prone',
      }),
    }),
  })
  
  export const {
    useAddBusinessMutation,
    useDeleteBusinessMutation,
    useGetBusinessesQuery,
    useGetBusinessQuery,
    useUpdateBusinessMutation,
    useGetErrorProneQuery,
    useGetAllBusinessesQuery,
    useGetBusinessDetailsQuery
  } = businessApi
  
  export const {
    endpoints: { getBusiness, getAllBusinesses },
  } = businessApi
  
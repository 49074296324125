import { useEffect, useState } from "react";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { merge } from "../../app/services/merge";
import Edit from "./Edit";
import { Grid } from "./Grid";
import t from "../../app/services/translation";
import { addToast } from "../toast/toastSlice";
import { useDispatch } from "react-redux";
import { setLoader } from "../loader/loaderSlice";
type AddActionProps = {
    setAction: any,
    title?: string,
}
const DefaultAddAction = ({ setAction, title }: AddActionProps) => {

    return (
        <>
            <Link
                to={'./edit'}
                onClick={() => setAction('edit')}
                className="bg-blue-500 hover:bg-blue-600 text-white rounded-md px-4 py-2 cursor-pointer">
                Add {title}
            </Link>
        </>
    );
}
export const convertFormDataToObject = (formData: any) => {
    let finalData: any = {};
    for (let key in formData) {
        if (key.indexOf(".") !== -1) {
            let indexes = key.split(".");
            let referenceObject = finalData;
            indexes.forEach((value, index) => {
                if (index == (indexes.length - 1)) {
                    referenceObject[value] = formData[key];
                }
                else {
                    if (typeof finalData[value] === 'undefined') {
                        referenceObject[value] = {};

                    }
                    referenceObject = referenceObject[value];
                }
            });
        }
        else {
            finalData[key] = formData[key];
        }
    }
    return finalData;
}

const RenderEdit = (props: any) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id, entity } = useParams();
    let [searchParams] = useSearchParams();
    let entityCodeParam = searchParams.get('entity');
    let entityCode = entityCodeParam || entity;
    //const { data: entity, isLoading } = getEntity.useQuery("id=" + id + "&entity=" + entityCode);

    let form = props.form;
    const [fields, setFields] = useState(form.fields);
    let currentEntity: any = false;
    console.log(props);
    //console.log(props.data);
    if (id && entityCode) {

        console.log(id);

        currentEntity = props.data.find((entity: any) => {
            return (entity[props.schema.primaryKey] === id);
        });

        if (!currentEntity) {
            //const { data: entity, isLoading } = getEntity.useQuery("id=" + id + "&entity=" + entityCode);
            //currentEntity = entity
        }

        for (let code in currentEntity) {
            if (form.fields[code]) {
                form.fields[code].value = currentEntity[code];
            }

        }

    }
    //console.log('currentEntity', currentEntity);
    // re run component if entity changed
    /*useEffect(() => {
        setFields(form.fields);
    }, [form.fields]);
    */
    const handleSubmit = async (e: any) => {
        dispatch(setLoader(true));
        //console.log(e);
        //e.preventDefault();

        const formSave = new CustomEvent("formSave", {
            detail: {
                formEvent: e,
            },
        });
        if (e.target.reportValidity()) {
            const data = new FormData(e.target);
            if (!data.get("entity")) {
                data.set("entity", entityCode ?? "");
            }
            //console.log(formData);
            if (id && entityCode) {
                const formData = Object.fromEntries(data);
                formData.id = id;
                formData.entity = entityCode;
                try {
                    await props.updateEntity(convertFormDataToObject(formData)).unwrap();
                    form.eventTarget.dispatchEvent(formSave);
                    dispatch(addToast({ type: "success", message: props.schema.title + " saved successfully." }));
                    dispatch(setLoader(false));
                }
                catch (e) {
                    console.log(e);
                    dispatch(addToast({ type: "error", message: "Unable to save" + props.schema.title + "." }));

                    dispatch(setLoader(false));
                }
                //  console.log('Dispatch save event');

            }
            else {
                try {

                    const formData = Object.fromEntries(data);

                    await props.addEntity(formData).unwrap();
                    //console.log(props.schema);
                    dispatch(addToast({ type: "success", message: props.schema.title + " saved successfully." }));
                    dispatch(setLoader(false));
                    navigate(props.redirectRoute);
                }
                catch (e: any) {
                    dispatch(addToast({ type: "error", message: e.data.message }));
                    dispatch(setLoader(false));
                }

            }

            //navigate(props.redirectRoute);
        }

    }

    // props.allowedList.add
    if (form.editComponent) {
        return form.editComponent;
    }
    return (<><Edit form={form} handleSubmit={handleSubmit} fields={fields} FormExtraFields={props.FormExtraFields} FormExtraLinks={props.FormExtraLinks} /></>);
}

const RenderByAction = (props: any) => {
    const dispatch = useDispatch();
    //const [deleteEntity, { isLoading: isDeleting }] = useDeleteEntityMutation()
    let parentProps: any = props.parentProps;
    let propsClone = JSON.parse(JSON.stringify(props.cloneProps));
    console.log('propsClone', propsClone);
    let actions = {
        "add": parentProps?.allowedList?.add && <DefaultAddAction key="add" title={parentProps.title} setAction={props.setAction} />
    };
    let actionItems = [];
    for (const [key, value] of Object.entries(actions)) {
        if (parentProps.actions) {
            if (parentProps.actions[key]) {
                actionItems.push(parentProps.actions[key]);
            }
            else {
                actionItems.push(value);
            }
        }
    }
    let form: any = {};

    if (propsClone.form) {
        form = { ...propsClone.form };
    }
    else {
        form = {
            title: propsClone.title,
        };

    }
    let fields: any = {};
    for (let fieldKey in propsClone.schema.headers) {
        let field = { ...propsClone.schema.headers[fieldKey] };
        field.type = field.type ?? 'text';
        field['key'] = field.dataIndex ?? field.code;
        fields[field.code] = field;

        //fields.push({ ...field, type: 'text', key: field.code });
    }
    //console.log('form.fields', { ...form.fields });
    form.fields = merge(fields, form.fields);
    //form.fields = fields;
    console.log('fields', fields);
    console.log('form.fields', form.fields);

    propsClone.schema.headers['action'] = {
        title: 'Action',
        code: 'action',
        actions: {
            ...parentProps.schema.headers?.action?.actions,
        },


    };
    if (parentProps?.allowedList?.edit) {
        propsClone.schema.headers.action.actions.edit = {
            title: t('Edit'),
            class: 'text-indigo-600 hover:text-indigo-900 cursor-pointer',
            to: './edit/:id?entity=:entity',
            onClick: (event: any, data: any) => {
            }
        };
    }
    if (parentProps?.allowedList?.delete) {
        propsClone.schema.headers.action.actions.delete = {
            title: t('Remove'),
            class: 'text-red-600 hover:text-red-900 ml-4 cursor-pointer',
            to: './edit/:id',
            onClick: (event: any, data: any, addMessage: any) => {
                event.preventDefault();
                if (window.confirm(t('Really want to remove %s ?', [data[propsClone.schema.primaryKey]]))) {
                    parentProps.deleteEntity(data);
                    addMessage("success", propsClone.schema.title + " deleted successfully.");
                    //dispatch(remove({ entityCode: entityCode, entityId: data.id }));
                    //if (parentProps.apiEndPoints.deleteEntity) {
                    //parentProps.apiEndPoints.deleteEntity.useMutation(data);
                    //parentProps.setDataId(uuidv4());
                    //}
                }
            }
        };
    }

    // gridData.columns = merge(gridColumns,gridData.columns);



    propsClone.schema.headers['action'].renderer = (data: any) => {
        let actions = propsClone.schema.headers['action'].actions;
        let actionButtons = [];
        for (let actionKey in actions) {
            let action = actions[actionKey];
            actionButtons.push(
                <Link key={data.entity + '-' + data[propsClone.schema.primaryKey] + '-' + actionKey}
                    to={action.to.replace(':id', encodeURIComponent(data[propsClone.schema.primaryKey])).replace(':entity', data.entity ?? propsClone.schema.entityCode)}
                    onClick={(e) => {
                        const addMessage = (type: string, message: string) => {
                            dispatch(addToast({ type: type, message: message }));
                        };
                        action.onClick(e, data, addMessage);
                    }}
                    className={action.class}>
                    {action.title}
                </Link>

                /*
                <Form
                    method={actionKey == 'delete' ? "DELETE" : "GET"}
                    action={action.to.replace(':id', data.id)}

                >
                    <button type="submit" name="id" value={data.id} >{action.title}</button>
                </Form>
                */
            );
        }

        return (<>{actionButtons}</>);
    }


    let data;
    if (parentProps.data) {
        data = parentProps.data;
    }
    else {
        data = [];
    }
    const eventTarget = new EventTarget();
    form.eventTarget = eventTarget;
    return (<>
        {props.action === 'grid' && <Grid title={parentProps.title} data={data} schema={propsClone.schema} actions={actionItems} massAction={parentProps.massAction} page={parentProps.page} onPrevPage={parentProps.onPrevPage} onNextPage={parentProps.onNextPage} />}
        {props.action === 'edit' && <RenderEdit
            redirectRoute={propsClone.gridRoute}
            form={form}
            data={data}
            addEntity={parentProps.addEntity}
            updateEntity={parentProps.updateEntity}
            FormExtraFields={parentProps.FormExtraFields}
            schema={propsClone.schema}
            FormExtraLinks={parentProps.FormExtraLinks}
        />}

    </>);
}
export const Crud = (props: any) => {
    let copyOfProps = JSON.parse(JSON.stringify(props));
    let { gridAction } = useParams();

    let gAction = props.action ? props.action : gridAction ? gridAction : 'grid';
    const [action, setAction] = useState(gAction);

    let schema = copyOfProps.schema;
    if (schema.headers) {

        for (let key in schema.headers) {
            schema.headers[key] = merge(schema.headers[key] ?? {}, schema.attributes[key] ?? {});
        }

    }
    else {

        schema.headers = schema.attributes;
    }
    copyOfProps.schema = schema;
    useEffect(() => {
        setAction(gAction);
    }, [gAction]);
    return (<>
        <RenderByAction cloneProps={copyOfProps} parentProps={props} action={action} setAction={setAction} />
    </>);
}


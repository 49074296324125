import { createApi, fetchBaseQuery, retry, FetchArgs, BaseQueryApi } from '@reduxjs/toolkit/query/react'
import { RootState } from '../store'

// Create our baseQuery instance
const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URL,
  prepareHeaders: (headers, { getState }) => {
    // By default, if we have a token in the store, let's use that for authenticated requests
    const token = (getState() as RootState).auth.idToken
    const currentBusiness = (getState() as RootState).auth.currentBusiness;
    if (token) {
      headers.set('authorization', `Bearer ${token}`)
    }
    if(currentBusiness){
      headers.set('Business-Id', currentBusiness.id);
    }
    return headers
  },
})

const baseQueryWithRetry = retry(baseQuery, { maxRetries: 0 })

const dynamicBaseQuery = async (
  args: string | FetchArgs,
  api: BaseQueryApi,
  extraOptions: any
) => {
  const state = api.getState() as RootState; // Access Redux state
  const dynamicBaseUrl = state.auth.currentBusiness?.restApiUrl || process.env.REACT_APP_API_URL; // Fallback to default URL

  // Create a fetchBaseQuery instance with the dynamic baseUrl
  const rawBaseQuery = fetchBaseQuery({
    baseUrl: dynamicBaseUrl,
    prepareHeaders: (headers) => {
      const token = state.auth.idToken
      const currentBusiness = state.auth.currentBusiness;
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }
      if(currentBusiness){
        headers.set('Business-Id', currentBusiness.id);
      }
      return headers
    },
  });

  // Pass the args to the rawBaseQuery
  return rawBaseQuery(args, api, extraOptions);
};

/**
 * Create a base API to inject endpoints into elsewhere.
 * Components using this API should import from the injected site,
 * in order to get the appropriate types,
 * and to ensure that the file injecting the endpoints is loaded
 */
export const api = createApi({
  /**
   * `reducerPath` is optional and will not be required by most users.
   * This is useful if you have multiple API definitions,
   * e.g. where each has a different domain, with no interaction between endpoints.
   * Otherwise, a single API definition should be used in order to support tag invalidation,
   * among other features
   */
  reducerPath: 'splitApi',
  /**
   * A bare bones base query would just be `baseQuery: fetchBaseQuery({ baseUrl: '/' })`
   */
  baseQuery: baseQueryWithRetry,
  /**
   * Tag types must be defined in the original API definition
   * for any tags that would be provided by injected endpoints
   */
  tagTypes: ['Employees', 'Students','Businesses','Entity','Entities','Theme',"Themes","Activities","Activity","App","Plan"],
  /**
   * This api has endpoints injected in adjacent files,
   * which is why no endpoints are shown below.
   * If you want all endpoints defined in the same file, they could be included here instead
   */
  endpoints: () => ({}),
})

export const enhancedApi = api.enhanceEndpoints({
  endpoints: () => ({
    getPost: () => 'test',
  }),
})



export const businessApi = createApi({
  reducerPath: 'businessApi',
  baseQuery: dynamicBaseQuery, // Use the custom base query
  tagTypes: ['Entity','Entities'], // Define your tags here
  endpoints: (builder) => ({
  }),
});
import { FetchBaseQueryMeta } from "@reduxjs/toolkit/query";
import { businessApi as api } from '../api'

export interface S3Url {
    data?:any,
    id?:string|null,
    url?:string,
    key?:string

}
export const s3BusinessApi = api.injectEndpoints({
    endpoints: (build) => ({
      getBusinessSignedUrl: build.query<S3Url, string>({
        query: (params) => `signed-s3?json=1&${params}`,
      }),
      delete: build.query<S3Url, string>({
        query: (params) => `signed-s3?${params}`,
      }),
      getErrorProne: build.query<{ success: boolean }, void>({
        query: () => 'error-prone',
      }),
    }),
  })
  
  export const {
    useGetBusinessSignedUrlQuery
  } = s3BusinessApi
  
  export const {
    endpoints: { getBusinessSignedUrl },
  } = s3BusinessApi
  
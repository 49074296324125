import { Crud } from "../../crud/Crud";
import { useGetEntitiesQuery, entityApi, useDeleteEntityMutation, useAddEntityMutation, useUpdateEntityMutation } from "../../../app/services/api/businessEntity";
import GlobalConstants from '../../../helper/GlobalConstants';
import { useParams } from "react-router-dom";

export const CustomEntity = () => {
    //let data = useLoaderData();
    const { entity } = useParams();
    let entityCode = entity || "";
    const { data: entities, isLoading } = useGetEntitiesQuery('entities=' + entity);
    const [deleteEntity/*, { isLoading: isDeleting }*/] = useDeleteEntityMutation();
    const [addEntity] = useAddEntityMutation();
    const [updateEntity] = useUpdateEntityMutation();
    let form = {
        title: "Food Products",
    };
    return isLoading ? <></> : (<>
        <Crud title="Food Products"
            gridRoute={"/panel/business-entity/" + entity}
            schema={(GlobalConstants as any)[entityCode]}
            data={isLoading ? [] : entities}
            allowedList={{ list: true, add: true, view: true, edit: true, delete: true }}
            actions={{}}
            form={form}
            apiEndPoints={entityApi.endpoints}
            deleteEntity={deleteEntity}
            addEntity={addEntity}
            updateEntity={updateEntity}
        />
    </>);
}
import Education from "../features/icons/Education";
import Food from "../features/icons/Food";
import Pharmacy from "../features/icons/Pharmacy";
import Agriculture from "../features/icons/Agriculture";
import Other from "../features/icons/Other";

const GlobalConstants = {
    INPUT_TYPE_EMAIL: "email",
    INPUT_TYPE_PASSWORD: "password",

    BUSINESS_TYPE_EDUCATION: "education",
    BUSINESS_TYPE_PHARMACY: "pharmacy",
    BUSINESS_TYPE_FOOD: "food",
    BUSINESS_TYPE_AGRICULTURE: "agriculture",


    BUSINESS_TYPES: [
        {
            name: "Education",
            value: "education",
            isAvailable: true,
            icon: <Education />
        },
        {
            name: "Pharmacy",
            value: "pharmacy",
            isAvailable: true,
            icon: < Pharmacy />
        },
        {
            name: "Agriculture",
            value: "agriculture",
            isAvailable: true,
            icon: <Agriculture />
        },
        {
            name: "Food",
            value: "food",
            isAvailable: true,
            icon: <Food />
        },
        {
            name: "Other",
            value: "other",
            isAvailable: true,
            icon: <Other />
        }
    ],

    THEME_SCHEMA: {
        primaryKey: 'sk',
        title: 'Theme',
        entityCode: 'base_themes',
        attributes: {
            title: {
                title: "Title",
                code: "name",
                dataIndex: "name",
                required: true
            },
            code: {
                title: "Code",
                code: "code",
                dataIndex: "code",
                required: true
            },
            business_code: {
                title: "Business",
                code: "business_code",
                dataIndex: "business_code",
            },
            type: {
                title: "Type",
                code: "type",
                dataIndex: "type",
                type: "select",
                options: [
                    {
                        value: "react",
                        label: "React"
                    },
                    {
                        value: "html",
                        label: "Html"
                    },
                    {
                        value: "html-src",
                        label: "Html Src"
                    }

                ]
            },
            image: {
                title: "Image",
                code: "image",
                dataIndex: "image",
                type: "image"
            },
            pages: {
                title: "Pages",
                code: "pages",
                dataIndex: "pages",
                type: "dontshow",
                showOnGrid: false
            },

        }
    },

    food: {
        primaryKey: 'id',
        title: 'Food Products',
        entityCode: 'food',
        attributes: {
            name: {
                title: "Name",
                code: "name",
                dataIndex: "name",
            },
            sku: {
                title: "SKU",
                code: "sku",
                dataIndex: "sku",
            },
            price: {
                title: "Price",
                code: "price",
                dataIndex: "price",
            },
            stock: {
                title: "Stock",
                code: "stock",
                dataIndex: "stock",
            },
            image: {
                title: "Image",
                code: "image",
                dataIndex: "image",
                type: "s3_image",
                s3BucketIsForBusiness: true,
                s3Bucket: process.env.REACT_APP_S3_BUCKET,
                s3Directory: "business/entity",
                s3BaseUrl: process.env.REACT_APP_THEME_BUCKET_URL,
                isTemp: true
            },
            category: {
                title: "Category",
                code: "category",
                dataIndex: "category",
                type: "options",
                options: [
                    {
                        'value': 'food_products.dairy',
                        'label': 'Dairy Product'
                    },
                    {
                        'value': 'food_products.bread',
                        'label': 'Bread',
                    },
                    {
                        'value': 'food_products.cheese',
                        'label': 'Cheese',
                    },
                    {
                        'value': 'food_products.walnut',
                        'label': 'Walnut',
                    },
                    {
                        'value': 'food_products.cereal',
                        'label': 'Cereal',
                    }
                ]

            }
        }
    }

}

export default GlobalConstants;
import { useState } from "react"
import { merge } from "../../app/services/merge"
import Button from "../forms/form/Buttons"

type GridRow = {
  [key: string]: string,
}
type GridCol = {
  title: string,
  code: string,
  renderer?: any,
  type?: string,
  options?: any,
  dataIndex: string,
  showOnGrid?: boolean
}

type GridSchema = {
  primaryKey: string,
  headers: { [key: string]: GridCol },
  attributes: { [key: string]: GridCol },
}
type GridProps = {
  title: string,
  data: GridRow[],
  schema: GridSchema,
  actions?: any,
  massAction?: any,
  page?: number,
  onPrevPage?: any,
  onNextPage?: any
}
const renderHeaders = (columns: any) => {
  let headers = [];
  headers.push(
    (
      <td key="massaction" className="px-6 py-4 whitespace-nowrap">
        <div className="text-sm text-gray-900">#</div>
      </td>
    )
  );
  for (let index in columns) {

    let column = columns[index];
    if (typeof column.showOnGrid === 'undefined' || column.showOnGrid === true) {
      headers.push(
        <th key={'headers-' + column.code} scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
          {column.title}
        </th>
      );
    }

  }
  return headers;
}
const renderDataRow = (schema: GridSchema, row: any, massAction: any, handleMassActionChange: any) => {
  console.log('row', row);
  let headers = [];
  let columns = schema.headers;
  let optionsIndexes: any = {};
  for (let index in columns) {
    if (columns[index].type == 'options') {
      optionsIndexes[index] = {};
      for (let optionIndex in columns[index].options) {
        let option = columns[index].options[optionIndex];
        optionsIndexes[index][option.value] = option.label;
      }

    }
  }

  //console.log('optionsIndexes', optionsIndexes);
  //console.log('employee.teacher', optionsIndexes['type']['employee.teacher']);
  let checked: boolean = massAction?.selectedValues[row[schema.primaryKey]] ? true : false;
  headers.push(
    (
      <td key={'row-' + row[schema.primaryKey] + '-col-massaction'} className="px-6 py-4 whitespace-nowrap">
        <div className="text-sm text-gray-900"><input type="checkbox" name="massaction[]" defaultChecked={checked} value={row[schema.primaryKey]} onChange={handleMassActionChange} /></div>
      </td>
    )
  );
  for (let index in columns) {
    let column = columns[index];

    if (column?.type) {

    }
    else {
      column.type = 'text';
    }
    let key = 'row-' + row[schema.primaryKey] + '-col-' + column.code;
    const typeData = (column: any) => {
      switch (column.type) {
        case "options":
          return optionsIndexes[column.code][row[column.dataIndex]];
        case "image":
          return <img src={row[column.dataIndex]} height="60" width="60" alt={column.title} />;
        case "s3_image":
          return <img src={row[column.dataIndex]} height="60" width="60" alt={column.title} />;

        default:
          return row[column.dataIndex];

      }
    }
    //console.log(key);
    //console.log(column);
    if (typeof column.showOnGrid === 'undefined' || column.showOnGrid === true) {
      headers.push(
        (
          <td key={key} className="px-6 py-4 whitespace-nowrap">
            {
              column.renderer ? column.renderer(row) : <div className="text-sm text-gray-900"> {typeData(column)} </div>
            }

          </td>
        )
      );
    }
  }
  return headers;
}
export const Grid = ({ title, data, schema, actions, massAction, page, onPrevPage, onNextPage }: GridProps) => {
  let removed: any = {};
  let added: any = {};
  const [addedValuesToMass, setAddedValuesToMass] = useState(added);
  const [removedValuesToMass, setRemovedValuesToMass] = useState(removed);
  const size = 30;

  const handleMassActionChange = (e: any) => {

    if (massAction && typeof massAction.selectedValues[e.target.value] != 'undefined') {

      if (e.target.checked) {
        if (typeof removedValuesToMass[e.target.value] != 'undefined') {
          delete removedValuesToMass[e.target.value];
          setRemovedValuesToMass(removedValuesToMass);
          if (massAction?.setRemovedValuesToMass) {
            massAction?.setRemovedValuesToMass(removedValuesToMass);
          }
        }
      }
      else {
        removedValuesToMass[e.target.value] = e.target.value;
        setRemovedValuesToMass(removedValuesToMass);
        if (massAction?.setRemovedValuesToMass) {
          massAction?.setRemovedValuesToMass(removedValuesToMass);
        }
      }
    }
    else {

      if (e.target.checked) {
        addedValuesToMass[e.target.value] = e.target.value;
        setAddedValuesToMass(addedValuesToMass);
        if (massAction?.setRemovedValuesToMass) {
          massAction?.setAddedValuesToMass(addedValuesToMass);
        }
      }
      else {
        if (typeof addedValuesToMass[e.target.value] != 'undefined') {
          delete addedValuesToMass[e.target.value];
          setAddedValuesToMass(addedValuesToMass);
          if (massAction?.setRemovedValuesToMass) {
            massAction?.setAddedValuesToMass(addedValuesToMass);
          }
        }
      }

    }
    console.log('addedValuesToMass', addedValuesToMass);
    console.log('removedValuesToMass', removedValuesToMass);


  }
  return (<>
    <div className=" flex flex-col m-6 gap-10 font-sans ">


      <div className="z-20 sticky top-0 flex justify-between items-center px-3 py-2 bg-[#fff]">
        <h2 className="text-[#303030] font-bold text-xl">{title}</h2>
        {actions}
      </div>
      <div className="bg-[#ffffff] rounded-lg relative flex flex-col ">

        <div className="col-span-4 md:col-span-4">
          <div className="bg-white rounded-lg shadow p-4">
            <div className="flex items-center justify-between mb-4">
              <h2 className="text-lg font-medium">{title}</h2>
            </div>
            <div className="flex flex-col">
              <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                  <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        <tr>
                          {
                            renderHeaders(schema.headers)
                          }
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200">
                        {
                          data.map((row: any) => {
                            return (
                              <tr key={row[schema.primaryKey]}>
                                {
                                  renderDataRow(schema, row, massAction, handleMassActionChange)
                                }
                              </tr>
                            )
                          })
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {
      page &&
      <div className="flex border-t border-gray-500 sticky bottom-0 items-center p-4 justify-between z-20 bg-[#fff]">
        <Button label="Prev" onClick={onPrevPage} customClass="bg-blue-500 hover:bg-blue-600 text-white font-bold py-1 px-4 rounded" />
        <div className="font-bold">Showing Items {(page - 1) * size + 1} - {page * size}</div>
        <Button label="Next" onClick={onNextPage} customClass="bg-blue-500 hover:bg-blue-600 text-white font-bold py-1 px-4 rounded" />

      </div>
    }
  </>);
}
import Text, { Hidden, Select, SelectAndSearch } from "../forms/form/Fields";
import { S3Images } from "../forms/form/fields/Images";
import { useSelector } from "react-redux";
import { selectCurrentBusiness } from "../auth/authSlice";

export const EditFields = (props: any) => {
    const currentBusiness = useSelector(selectCurrentBusiness);
    const prepareFields = (props: any) => {
        let fields = props.fields;
        let formFields = [];
        for (let fieldKey in fields) {
            formFields.push(prepareField(fields[fieldKey]));
        }

        return formFields;
    }
    const prepareField = (field: any) => {
        let f: any;
        switch (field.type) {
            case "select":
                f = <Select
                    label={field.title}
                    name={field.key}
                    key={field.key}
                    id={field.key}
                    value={field.value ?? field.default ?? ""}
                    setValue={
                        field.setValue ?? (() => { })
                    }
                    required={field.required}
                    options={field.options}
                />;
                break;
            case 'search':
                f = <SelectAndSearch
                    label={field.title}
                    key={field.key}
                    id={field.key}
                    value={field.value ?? field.default ?? ""}
                    setValue={
                        (val: string) => {
                            /*
                            let entityCopy = { ...entity, ...dependentParams };
                            entityCopy[fieldKey] = val;
                            setEntity(entityCopy)
                            */
                        }
                    }
                    required={field.required}
                    options={field.options}
                    multiSelect={field.multiSelect ?? false}
                />
                break;
            case "options":
                f = <Select
                    label={field.title}
                    name={field.key}
                    key={field.key}
                    id={field.key}
                    value={field.value ?? field.default ?? ""}
                    setValue={
                        field.setValue ?? (() => { })
                    }
                    required={field.required}
                    options={field.options}
                />;
                break;
            case "text":
                f = <Text
                    label={field.title}
                    name={field.key}
                    key={field.key}
                    id={field.key}
                    value={field.value ?? field.default ?? ""}
                    setValue={
                        field.setValue ?? (() => { })
                    }
                    required={field.required}
                    readOnly={field.readOnly}
                />
                break;
            case "hidden":
                f = <Hidden
                    name={field.key ?? field.code}
                    key={field.key ?? field.code}
                    id={field.key}
                    value={field.value ?? field.default ?? ""}
                />
                break;
            case "s3_image":
                let s3Bucket = field.s3Bucket ?? process.env.REACT_APP_S3_BUCKET;
                let s3BaseUrl = field.s3BaseUrl ?? process.env.REACT_APP_THEME_BUCKET_URL;
                if (field?.s3BucketIsForBusiness) {
                    s3Bucket = currentBusiness?.s3Bucket;
                    s3BaseUrl = "https://" + s3Bucket + ".s3.ap-south-1.amazonaws.com/"
                }



                f = <S3Images label={field.title}
                    value={field.value ? { [field.key]: { image: field.value, id: field.key } } : {}}
                    key={field.key ?? field.code}
                    id={field.key ?? field.code}
                    s3BucketIsForBusiness={field.s3BucketIsForBusiness ?? false}
                    s3Bucket={s3Bucket}
                    s3Directory={field.s3Directory}
                    required={field.required ?? true}
                    s3BaseUrl={s3BaseUrl}
                    isTemp={field.isTemp}
                    limit={1}

                />
                break;
            default:
            //f = <></>
        }
        return f;
    }
    return (<> {prepareFields(props)} </>);
}